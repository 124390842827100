<template>
  <v-card :loading="loading" class="pa-4 pa-sm-8" color="transparent" elevation="0">
    <h1 class="access-header">Create your Account!</h1>
    <social-auth v-if="false"/>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate()" id="register-form">

      <v-text-field :autofocus="true" ref="username" v-model="username" :rules="usernameRules" :loading="loading" label="Username" outlined required></v-text-field>

      <v-text-field ref="email" v-model="email" :rules="emailRules" label="Email" :loading="loading" outlined required></v-text-field>

      <v-text-field autocomplete="password" v-model="password" :loading="loading" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="passwordRules" :type="showPassword ? 'text' : 'password'" label="Password" outlined @click:append="showPassword = !showPassword" required></v-text-field>

      <v-radio-group v-model="role" row class="mt-0">
        <v-radio label="Student" value="freelancer"></v-radio>
        <v-radio label="Employer" value="employer"></v-radio>
      </v-radio-group>

      <v-btn type="submit" color="secondary" block depressed x-large class="mr-4" :disabled="loading" :loading="loading">
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
        Register
      </v-btn>

      <div class="mt-5 text-center">
        Already have an account? <router-link :to="{name: 'login'}">Log in</router-link>
      </div>

      <v-alert text outlined color="error" icon="mdi-fire" v-if="error" class="mt-5">
        {{ error }}
      </v-alert>
    </v-form>
  </v-card>
</template>

<script>
import { onLogin } from '@/vue-apollo.js'
// import * as Sentry from '@sentry/browser'
import SocialAuth from '../components/SocialAuth'
const REGISTER = require('../graphql/register.graphql')
export default {
  data: () => ({
    valid: true,
    loading: false,
    username: '',
    usernameRules: [v => !!v || 'Username is required'],
    password: '',
    passwordRules: [v => !!v || 'Password is required'],
    role: 'freelancer',
    email: '',
    emailRules: [v => !!v || 'Email is required'],
    showPassword: false,
    error: null
  }),
  computed: {
    fbStatus () {
      return this.$store.state.fbStatus
    }
  },
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.register()
      }
    },
    async register () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: REGISTER,
          variables: {
            email: this.email,
            username: this.username,
            password: this.password,
            role: this.role
          },
          update: (cache, { data: { register } }) => {
            onLogin(this.$apollo.provider.defaultClient, register.token)
            this.$router.push({ name: 'onboarding' })
          }
        })
      } catch (e) {
        this.loading = false
        this.error = e
        // Sentry.captureException(e)
        return e
      }
    }
  },
  components: {
    SocialAuth
  }
}
</script>
<style lang="scss" scoped>
  .access-header {
    text-align: center;
    padding: 1.5rem 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.75rem;
  }
  @media (max-width: 960px) {
    .access-header {
      font-size: 1.25rem;
    }
  }
</style>
